/*
 * Skin: Blue
 * ----------
 */
.skin-gs1 {
  //Navbar
  .main-header {
    .navbar {
      .sidebar-toggle {
        color: #fff;
        &:hover {
        }
      }
      @media (max-width: $screen-header-collapse) {
        .dropdown-menu {
          li {
            &.divider {
              background-color: rgba(255, 255, 255, 0.1);
            }
            a {
              color: #fff;
              &:hover {
                background: darken($dark-blue, 5%);
              }
            }
          }
        }
      }
    }
    //Logo
    .logo {
    }

    li.user-header {
      /*background-color: $dark-blue;*/
    }
  }

  //Content Header
  .content-header {
    background: #fff;
  }

  //Create the sidebar skin
  @include skin-dark-sidebar($dark-blue);
}

.skin-blue.layout-top-nav .main-header > .logo {
  @include logo-variant($dark-blue);
}
