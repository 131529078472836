/*
 * Core: General Layout Style
 * -------------------------
 */

%clearfix {
    zoom:1;
    &:before, &:after {
        content: "\0020";
        display: block;
        height: 0;
        overflow: hidden;
    }
    &:after {
        clear: both;
    }
}


html,
body {
  min-height: 100%;
  .layout-boxed & {
    height: 100%;
  }
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: Arial, sans-serif;
	overflow-x: hidden;
	overflow-y: scroll;
	color: #595f6d;
	background: #e9ecf1;
	font-size: 14px!important;
}

img {
	width: 100%;
	height: auto;
	display: block;
}

/* Type */
h2 {
	font-size: 1.0666rem;
}

p {
	padding: 0;
	margin: 0;
	color: #595f6d;
}

/* Layout */
.wrapper {
  @extend %clearfix;
  min-height: 100%;
  position: relative;
  overflow: hidden;
  .layout-boxed & {
    max-width: 1250px;
    margin: 0 auto;
    min-height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    position: relative;
  }
}

.layout-boxed {
  background: url(#{$boxed-layout-bg-image-path}) repeat fixed;
}

/*
 * Content Wrapper - contains the main content
 * ```.right-side has been deprecated as of v2.0.0 in favor of .content-wrapper  ```
 */
.content-wrapper,
.right-side,
.main-footer {
  //Using disposable variable to join statements with a comma
  $transition-rule: $transition-speed $transition-fn,
  margin $transition-speed $transition-fn;
  @include transition-transform($transition-rule);
  margin-left: $sidebar-width;
  z-index: 820;
  //Top nav layout
  .layout-top-nav & {
    margin-left: 0;
  }
  @media (max-width: $screen-xs-max) {
    margin-left: 0;
  }
  //When opening the sidebar on large screens
  .sidebar-collapse & {
    @media (min-width: $screen-sm) {
      margin-left: 0;
    }
  }
  //When opening the sidebar on small screens
  .sidebar-open & {
    @media (max-width: $screen-xs-max) {
      @include translate($sidebar-width, 0);
    }
  }
}

.content-wrapper,
.right-side {
  min-height: 100%;
  background-color: $body-bg;
  z-index: 800;
}

.main-footer {
  background: #fff;
  padding: 15px;
  color: #444;
  border-top: 1px solid $gray;
}

/* Fixed layout */
.fixed {
  .main-header,
  .main-sidebar,
  .left-side {
    position: fixed;
  }
  .main-header {
    top: 0;
    right: 0;
    left: 0;
  }
  .content-wrapper,
  .right-side {
    padding-top: 50px;
    @media (max-width: $screen-header-collapse) {
      padding-top: 100px;
    }
  }
  &.layout-boxed {
    .wrapper {
      max-width: 100%;
    }
  }
}

body.hold-transition {
  .content-wrapper,
  .right-side,
  .main-footer,
  .main-sidebar,
  .left-side,
  .main-header > .navbar,
  .main-header .logo {
    /* Fix for IE */
    @include transition(none);
  }
}

/* Content */
.content {
	width: 1200px;
	min-height: 250px;
	padding: 15px 50px;
	//@include container-fixed($grid-gutter-width);
	@media only screen and  #{$maxLaptop} {
		width: 100%;
	}
	@media only screen and #{$minLaptop} and #{$maxLaptop} {
		padding: 15px 30px;
	}

	&.full-width {
		width: 100%;
	}

}

/* H1 - H6 font */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: 'Roboto', sans-serif;
	color: #2f333f;
	font-weight: normal;
}

h1 {
	color: #484a5b;
}

/* General Links */
a {
  color: $link-color;
}

a:hover {
  outline: none;
  text-decoration: none;
  color: $link-hover-color;
}

a:active,
a:focus {
	outline: none;
	text-decoration: none;
}

/* Page Header */
.page-header {
  margin: 10px 0 20px 0;
  font-size: 22px;

  > small {
    color: #666;
    display: block;
    margin-top: 5px;
  }
}




























