/*
 * Component: Box
 * --------------
 */
.box {
	width: 100%;
	position: relative;
	background: #fff;
	box-shadow: $box-boxshadow;
	border-bottom: 1px solid $box-border-color;
	display: block;
	@include border-radius($box-border-radius,$box-border-radius,$box-border-radius,$box-border-radius);

	// Box color variations
	&.box-primary {
	border-top-color: $light-blue;
	}
	&.box-info {
	border-top-color: $aqua;
	}
	&.box-danger {
	border-top-color: $red;
	}
	&.box-warning {
	border-top-color: $yellow;
	}
	&.box-success {
	border-top-color: $green;
	}
	&.box-default {
	border-top-color: $gray;
	}

	// collapsed mode
	&.collapsed-box {
	.box-body,
	.box-footer {
	  display: none;
	}
	}

	.nav-stacked {
	> li {
	  border-bottom: 1px solid $box-border-color;
	  margin: 0;
	  &:last-of-type {
		border-bottom: none;
	  }
	}
	}

	// fixed height to 300px
	&.height-control {
	.box-body {
	  max-height: 300px;
	  overflow: auto;
	}
	}

	.border-right {
	border-right: 1px solid $box-border-color;
	}
	.border-left {
	border-left: 1px solid $box-border-color;
	}

	//SOLID BOX
	//---------
	//use this class to get a colored header and borders

	&.box-solid {
	border-top: 0;
	> .box-header {
	  .btn.btn-default {
		background: transparent;
	  }
	  .btn,
	  a {
		&:hover {
		  background: rgba(0, 0, 0, 0.1);
		}
	  }
	}

	// Box color variations
	&.box-default {
	  @include box-solid-variant($gray, #444);
	}
	&.box-primary {
	  @include box-solid-variant($light-blue);
	}
	&.box-info {
	  @include box-solid-variant($aqua);
	}
	&.box-danger {
	  @include box-solid-variant($red);
	}
	&.box-warning {
	  @include box-solid-variant($yellow);
	}
	&.box-success {
	  @include box-solid-variant($green);
	}

	> .box-header > .box-tools .btn {
	  border: 0;
	  box-shadow: none;
	}

	// Fix font color for tiles
	&[class*='bg'] {
	  > .box-header {
		color: #fff;
	  }
	}

	}

	//BOX GROUP
	.box-group {
	> .box {
	  margin-bottom: 5px;
	}
	}

	// jQuery Knob in a box
	.knob-label {
	text-align: center;
	color: #333;
	font-weight: 100;
	font-size: 12px;
	margin-bottom: 0.3em;
	}


}

.box,
.overlay-wrapper {
  // Box overlay for LOADING STATE effect
  > .overlay,
  > .loading-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .overlay {
    z-index: 50;
    background: rgba(255, 255, 255, 0.7);
    @include border-radius($box-border-radius,$box-border-radius,$box-border-radius,$box-border-radius);
    > .fa {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
      color: #000;
      font-size: 30px;
    }
  }

  .overlay.dark {
    background: rgba(0, 0, 0, 0.5);
  }
}

//Add clearfix to header, body and footer
.box-header,
.box-body,
.box-footer {
  @include clearfix();
}

//Box header
.box-header {
  color: #484a5b;
  display: block;
  padding: 17px 20px;
  position: relative;
	@include border-radius($box-border-radius,$box-border-radius,0,0);

  //Add bottom border
  &.with-border {
	  border-top: 2px solid #4d93d7;
    border-bottom: 1px solid #d2d4df;
	  @include box-shadow(0px 2px 3px 0px rgba(0, 0, 0, 0.07));
    .collapsed-box & {
      border-bottom: none;
    }
  }

	.box-actions {
		position: absolute;
		right: 20px;
		top: 15px;

		.action {
			border: 1px solid #dfe1e9;
			font-size: 13px;
			padding: 1px 7px;
			display: inline-block;
			@include border-radius(2px,2px,2px,2px);
			@include transition(all .2s ease-out);
			&:hover {
				border: 1px solid #60a8ee;
			}

			.fa {
				font-size: 10px;
				position: relative;
				top: -1px;
			}
		}
	}

	h2 {
		text-transform: uppercase;
		display: inline-block;
		font-size: 15px;
	}

	> .fa {
		display: inline-block;
		font-size: 14px;
	}

  //Icons and box title
  > .fa,
  > .glyphicon,
  > .ion,
  .box-title {
    display: inline-block;
    margin: 0;
	  position: relative;
	  top: -1px;
    line-height: 1;
	  color: #60a8ee;
	  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  }
  > .fa,
  > .glyphicon,
  > .ion {
    margin-right: 5px;
  }
  > .box-tools {
    position: absolute;
    right: 10px;
    top: 5px;
    [data-toggle="tooltip"] {
      position: relative;
    }

    &.pull-right {
      .dropdown-menu {
        right: 0;
        left: auto;
      }
    }
  }
}

//Box Tools Buttons
.btn-box-tool {
  padding: 5px;
  font-size: 12px;
  background: transparent;
  color: darken($box-default-border-top-color, 20%);
  .open &,
  &:hover {
    color: darken($box-default-border-top-color, 40%);
  }
  &.btn:active {
    box-shadow: none;
  }
}

//Box Body
.box-body {
  //@include border-radius(0, 0, $box-border-radius, $box-border-radius);
  padding: $box-padding;
  .no-header & {
    @include border-top-radius($box-border-radius);
  }
  // Tables within the box body
  > .table {
    margin-bottom: 0;
  }

  // Calendar within the box body
  .fc {
    margin-top: 5px;
  }

  .full-width-chart {
    margin: -19px;
  }
  &.no-padding .full-width-chart {
    margin: -9px;
  }

  .box-pane {
    @include border-radius(0, 0, $box-border-radius, 0);
  }
  .box-pane-right {
    @include border-radius(0, 0, 0, $box-border-radius);
  }
}

//Box footer
.box-footer {
  @include border-radius(0, 0, $box-border-radius, $box-border-radius);
  padding: $box-padding;
}

.chart-legend {
  @extend .list-unstyled;
  margin: 10px 0;
  > li {
    @media (max-width: $screen-sm-max) {
      float: left;
      margin-right: 10px;
    }
  }
}

//Comment Box
.box-comments {
  background: #f7f7f7;
  .box-comment {
    @include clearfix();
    padding: 8px 0;
    border-bottom: 1px solid #eee;
    &:last-of-type {
      border-bottom: 0;
    }
    &:first-of-type {
      padding-top: 0;
    }
    img {
      @extend .img-sm;
      float: left;
    }
  }
  .comment-text {
    margin-left: 40px;
    color: #555;
  }
  .username {
    color: #444;
    display: block;
    font-weight: 600;
  }
  .text-muted {
    font-weight: 400;
    font-size: 12px;
  }
}

//Widgets
//-----------

/* Widget: TODO LIST */

.todo-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
  // Todo list element
  > li {
    @include border-radius(2px,2px,2px,2px);
    padding: 10px;
    background: #f4f4f4;
    margin-bottom: 2px;
    border-left: 2px solid #e6e7e8;
    color: #444;
    &:last-of-type {
      margin-bottom: 0;
    }

    > input[type='checkbox'] {
      margin: 0 10px 0 5px;
    }

    .text {
      display: inline-block;
      margin-left: 5px;
      font-weight: 600;
    }

    // Time labels
    .label {
      margin-left: 10px;
      font-size: 9px;
    }

    // Tools and options box
    .tools {
      display: none;
      float: right;
      color: $red;
      // icons
      > .fa, > .glyphicon, > .ion {
        margin-right: 5px;
        cursor: pointer;
      }

    }
    &:hover .tools {
      display: inline-block;
    }

    &.done {
      color: #999;
      .text {
        text-decoration: line-through;
        font-weight: 500;
      }

      .label {
        background: $gray !important;
      }
    }
  }

  // Color varaity
  .danger {
    border-left-color: $red;
  }
  .warning {
    border-left-color: $yellow;
  }
  .info {
    border-left-color: $aqua;
  }
  .success {
    border-left-color: $green;
  }
  .primary {
    border-left-color: $light-blue;
  }

  .handle {
    display: inline-block;
    cursor: move;
    margin: 0 5px;
  }

}

// END TODO WIDGET

/* Chat widget (DEPRECATED - this will be removed in the next major release. Use Direct Chat instead)*/
.chat {
  padding: 5px 20px 5px 10px;

  .item {
    @include clearfix();
    margin-bottom: 10px;
    // The image
    > img {
      width: 40px;
      height: 40px;
      border: 2px solid transparent;
      @include border-radius(50%,50%,50%,50%);
    }

    > .online {
      border: 2px solid $green;
    }
    > .offline {
      border: 2px solid $red;
    }

    // The message body
    > .message {
      margin-left: 55px;
      margin-top: -40px;
      > .name {
        display: block;
        font-weight: 600;
      }
    }

    // The attachment
    > .attachment {
      @include border-radius($attachment-border-radius,$attachment-border-radius,$attachment-border-radius,$attachment-border-radius);
      background: #f4f4f4;
      margin-left: 65px;
      margin-right: 15px;
      padding: 10px;
      > h4 {
        margin: 0 0 5px 0;
        font-weight: 600;
        font-size: 14px;
      }
      > p, > .filename {
        font-weight: 600;
        font-size: 13px;
        font-style: italic;
        margin: 0;

      }
      @include clearfix();
    }
  }

}

//END CHAT WIDGET

//Input in box
.box-input {
  max-width: 200px;
}

//A fix for panels body text color when placed within
// a modal
.modal {
  .panel-body {
    color: #444;
  }
}
