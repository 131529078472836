/*
 * Component: Alert
 * ----------------
 */

.alert {
  @include border-radius(3px,3px,3px,3px);
  h4 {
    font-weight: 600;
  }
  .icon {
    margin-right: 10px;
  }
  .close {
    color: #000;
    @include opacity(.2);
    &:hover {
      @include opacity(.5);
    }
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
}

//Alert Variants
.alert-success {
  @extend .bg-green;
  border-color: darken($green, 5%);
}

.alert-danger,
.alert-error {
  @extend .bg-red;
  border-color: darken($red, 5%);
}

.alert-warning {
  @extend .bg-yellow;
  border-color: darken($yellow, 5%);
}

.alert-info {
  @extend .bg-aqua;
  border-color: darken($aqua, 5%);
}
