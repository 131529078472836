/*
 * Component: Main Header
 * ----------------------
 */

.main-header {
	position:   relative;
	max-height: 100px;
	z-index:    1030;
	background: #135faa;

	//Navbar
	> .navbar {
		@include transition(margin-left $transition-speed $transition-fn);
		margin-bottom: 0;
		margin-left:   $sidebar-width;
		border:        none;
		min-height:    $navbar-height;
		border-radius: 0;
		.layout-top-nav & {
			margin-left: 0;
		}
	}
	//Navbar search text input
	#navbar-search-input.form-control {
		background:   rgba(255, 255, 255, .2);
		border-color: transparent;
		&:focus,
		&:active {
			border-color: rgba(0, 0, 0, .1);
			background:   rgba(255, 255, 255, .9);
		}
		&::-moz-placeholder {
			color:   #cccccc;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			color: #cccccc;
		}
		&::-webkit-input-placeholder {
			color: #cccccc;
		}
	}

	//Navbar Right Menu
	.navbar-custom-menu,
	.navbar-right {
		@media (max-width: $screen-sm-max) {
			a {
				color:      inherit;
				background: transparent;
			}
		}
	}

	.navbar-right {
		float: right;

		@media (max-width: $screen-header-collapse) {
			float: none;
			.navbar-collapse & {
				margin: 7.5px -15px;
			}

			> li {
				color:  inherit;
				border: 0;
			}
		}

		ul {
			li {
				border-left: 1px solid #286db2;
				&:last-child {
					border-right: 1px solid #286db2;
				}

				a {
					padding: 16px 25px;

					.fa {
						font-size: 20px;
						color:     #74b8fc;
					}
				}
			}
		}

		.notifications-menu {

			ul.dropdown-menu {
				width:   350px;
				border:  none;
				z-index: 8;
				@include box-shadow(0px 8px 20px 0px rgba(0, 0, 0, 0.16));

				li {
					border:     none;
					border-top: 1px solid #e4e6f0;
					&:first-child {
						border: none;
					}
					a {
						padding: 10px 15px;

						span {
							font-size: 11px;
						}
						h2 {
							font-size: 13px;
						}
						p {
							font-size: 13px;
						}

						&.btn {
							color: #ffffff;
							@include border-radius(0, 0, 3px, 3px);
							&:hover {
								background-color: #16a688;
							}
						}
					}
				}
			}

			&.open {
				> a {
					position:   relative;
					background: #ffffff;
					z-index:    9;
					.fa {
						color: #3d92e5;
					}
				}
			}
		}

		.user-menu {

			ul.dropdown-menu {
				width:   230px;
				border:  none;
				z-index: 8;
				@include box-shadow(0px 8px 20px 0px rgba(0, 0, 0, 0.16));

				li {
					border:     none;
					border-top: 1px solid #e4e6f0;
					&:first-child {
						border: none;
					}

					a {
						text-align: right;
						padding:    10px 15px;

						span {
							font-size: 11px;
						}
						h2 {
							font-size: 13px;
						}
						p {
							font-size: 13px;
						}

						&.btn {
							color: #ffffff;
							@include border-radius(0, 0, 3px, 3px);
							&:hover {
								background-color: #16a688;
							}
						}
					}

				}
			}

			&.open {
				> a {
					position:   relative;
					background: #ffffff;
					z-index:    9;
					.fa {
						color: #3d92e5;
					}
				}
			}
		}

	}

	//Navbar toggle button
	.sidebar-toggle {
		float:            left;
		background-color: transparent;
		background-image: none;
		padding:          $navbar-padding-vertical $navbar-padding-horizontal;
		//Add the fontawesome bars icon
		font-family:      fontAwesome;
		&:before {
			content: "\f0c9";
		}
		&:hover {
			color: #ffffff;
		}
		&:focus,
		&:active {
			background: transparent;
		}
	}
	.sidebar-toggle .icon-bar {
		display: none;
	}
	//Navbar User Menu
	.navbar .nav > li.user > a {
		> .fa,
		> .glyphicon,
		> .ion {
			margin-right: 5px;
		}
	}

	.navbar-custom-menu {

		ul {
			li {
				border-left: 1px solid #286db2;
				&:last-child {
					border-right: 1px solid #286db2;
				}
				a {
					padding:        15px 35px;
					text-transform: uppercase;
					color:          #ffffff;
					font-family:    "Roboto", sans-serif;
					@include transition(all .2s ease-out);

					&:hover {
						background: #11589d;
					}

					span {
						width:          24px;
						height:         24px;
						background:     #1f70c0;
						display:        inline-block;
						vertical-align: middle;
						text-align:     center;
						position:       relative;
						@include border-radius(50%, 50%, 50%, 50%);

						i {
							color:    #93c7fa;
							position: absolute;
							top:      50%;
							left:     50%;
							@include transform(translate(-50%, -50%));
						}
					}

					div {
						display:        inline-block;
						vertical-align: middle;
						padding:        0 0 0 3px;
					}
				}

				&.active {
					a {
						background: #11589d;
					}
				}
			}
		}

	}

	//Labels in navbar
	.navbar .nav > li > a > .label {
		position:    absolute;
		top:         9px;
		right:       7px;
		text-align:  center;
		font-size:   9px;
		padding:     2px 3px;
		line-height: .9;
	}

	//Logo bar
	.logo {
		@include transition(width $transition-speed $transition-fn);
		display:    block;
		float:      left;
		height:     $navbar-height;
		text-align: center;
		width:      $sidebar-width;
		overflow:   hidden;
		//Add support to sidebar mini by allowing the user to create
		//2 logo designs. mini and lg
		.logo-lg {
			//should be visibile when sidebar isn't collapsed
			display: block;
		}
		.logo-mini {
			display: none;
		}

		> div {
			width:   80px;
			padding: 3px 0 0 0;
			display: inline-block;
		}
	}
	//Navbar Brand. Alternative logo with layout-top-nav
	.navbar-brand {
		color: #ffffff;
	}
}

// Content Header
.content-header {
	position:      relative;
	padding:       15px 50px;
	margin:        0 0 15px 0;
	border-bottom: 1px solid #d5dbe0;
	@include box-shadow(0px 2px 3px 0px rgba(0, 0, 0, 0.07));
	@media only screen and #{$minLaptop} and #{$maxLaptop} {
		padding: 15px 30px;
	}

	> h1 {
		font-size:   22px;
		display:     inline-block;
		font-weight: normal;
	}

	> .breadcrumb {
		float:         right;
		background:    transparent;
		margin-top:    0;
		margin-bottom: 0;
		font-size:     12px;
		padding:       7px 5px;
		position:      absolute;
		top:           15px;
		right:         10px;
		@include border-radius(2px, 2px, 2px, 2px);
		> li > a {
			color:           #444444;
			text-decoration: none;
			display:         inline-block;
			> .fa, > .glyphicon, > .ion {
				margin-right: 5px;
			}
		}
		> li + li:before {
			content: ">\00a0";
		}
	}

	@media (max-width: $screen-sm-max) {
		> .breadcrumb {
			position:     relative;
			margin-top:   5px;
			top:          0;
			right:        0;
			float:        none;
			background:   $gray;
			padding-left: 10px;
			li:before {
				color: darken($gray, 20%);
			}
		}
	}
}

.navbar-toggle {
	color:   #ffffff;
	border:  0;
	margin:  0;
	padding: $navbar-padding-vertical $navbar-padding-horizontal;
}

//Control navbar scaffolding on x-small screens
@media (max-width: $screen-sm-max) {
	.navbar-custom-menu .navbar-nav > li {
		float: left;
	}

	//Dont't let links get full width
	.navbar-custom-menu .navbar-nav {
		margin: 0;
		float:  left;
	}

	.navbar-custom-menu .navbar-nav > li > a {
		padding-top:    15px;
		padding-bottom: 15px;
		line-height:    20px;
	}
}

// Collapse header
@media (max-width: $screen-header-collapse) {
	.main-header {
		position: relative;
		.logo,
		.navbar {
			width: 100%;
			float: none;
		}
		.navbar {
			margin: 0;
		}
		.navbar-custom-menu {
			float: right;
		}
	}
}

.navbar-collapse.pull-left {
	@media (max-width: $screen-sm-max) {
		float: none !important;
		+ .navbar-custom-menu {
			display:  block;
			position: absolute;
			top:      0;
			right:    40px;
		}
	}
}
