//AdminLTE 2 Variables.less
//=========================

//PATHS
//--------------------------------------------------------

$boxed-layout-bg-image-path: "../img/boxed-bg.png";

//FONT SIZES
//--------------------------------------------------------
$fontSize22: 1.5714rem;
$fontSize20: 1.4285rem;
$fontSize18: 1.2857rem;
$fontSize17: 1.2142rem;
$fontSize16: 1.1428rem;
$fontSize15: 1.0714rem;
$fontSize14: 1rem;
$fontSize13: 0.9285rem;

//COLORS
//--------------------------------------------------------
//Primary
$light-blue: #468bd9;
$dark-blue: #135faa;
//Danger
$red: #dd4b39;
//Success
$green: #00a65a;
//Info
$aqua: #00c0ef;
//Warning
$yellow: #f39c12;
$blue: #1a1c22;
$navy: #001F3F;
$teal: #39CCCC;
$olive: #3D9970;
$lime: #01FF70;
$orange: #FF851B;
$fuchsia: #F012BE;
$purple: #605ca8;
$maroon: #D81B60;
$black: #111;
$gray: #d2d6de;

//LAYOUT
//--------------------------------------------------------

//Side bar and logo width
$sidebar-width: 280px;
//Boxed layout maximum width
$boxed-layout-max-width: 1024px;
//When the logo should go to the top of the screen
$screen-header-collapse: $screen-xs-max;

//Link colors (Aka: <a> tags)
$link-color: #56a2ec;
$link-hover-color: #438bd1;

//Body background (Affects main content background only)
$body-bg: #e9ecf1;

//SIDEBAR SKINS
//--------------------------------------------------------

//Dark sidebar
$sidebar-dark-bg: #222d32;
$sidebar-dark-hover-bg: darken($sidebar-dark-bg, 2%);
$sidebar-dark-color: lighten($sidebar-dark-bg, 60%);
$sidebar-dark-hover-color: #fff;
$sidebar-dark-submenu-bg: lighten($sidebar-dark-bg, 5%);
$sidebar-dark-submenu-color: lighten($sidebar-dark-submenu-bg, 40%);
$sidebar-dark-submenu-hover-color: #fff;

//Light sidebar
$sidebar-light-bg: #f9fafc;
$sidebar-light-hover-bg: lighten(#f0f0f1, 1.5%);
$sidebar-light-color: #444;
$sidebar-light-hover-color: #000;
$sidebar-light-submenu-bg: $sidebar-light-hover-bg;
$sidebar-light-submenu-color: #777;
$sidebar-light-submenu-hover-color: #000;

//CONTROL SIDEBAR
//--------------------------------------------------------
$control-sidebar-width: $sidebar-width;

//BOXES
//--------------------------------------------------------
$box-border-color: #fff;
$box-border-radius: 2px;
$box-footer-bg: #f6f6f6;
$box-boxshadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.07);
$box-padding: 20px;

//Box variants
$box-default-border-top-color: #d2d6de;

//BUTTONS
//--------------------------------------------------------
$btn-boxshadow: none;

//PROGRESS BARS
//--------------------------------------------------------
$progress-bar-border-radius: 1px;
$progress-bar-sm-border-radius: 1px;
$progress-bar-xs-border-radius: 1px;

//FORMS
//--------------------------------------------------------
$input-radius: 2px;

//BUTTONS
//--------------------------------------------------------

//Border radius for non flat buttons
$btn-border-radius: 2px;

//DIRECT CHAT
//--------------------------------------------------------
$direct-chat-height: 250px;
$direct-chat-default-msg-bg: $gray;
$direct-chat-default-font-color: #444;
$direct-chat-default-msg-border-color: $gray;

//CHAT WIDGET
//--------------------------------------------------------
$attachment-border-radius: 3px;

//TRANSITIONS SETTINGS
//--------------------------------------------------------

//Transition global options
$transition-speed: .3s;
$transition-fn: ease-in-out;
//cubic-bezier(0.32,1.25,0.375,1.15);


//CUSTOM
//--------------------------------------------------------
$modalContentWidth: 390px;

//CUSTOM VIEWPOINTS
//--------------------------------------------------------
$desktop: "(min-width: 1280px)";

$maxLaptop: "(max-width: 1366px)";
$minLaptop: "(min-width: 1280px)";

$maxTabletLand: "(max-width: 1279px)";
$minTabletLand: "(min-width: 1024px)";

$maxTabletPort: "(max-width: 1023px)";
$minTabletPort: "(min-width: 768px)";

$phone: "(max-width: 767px)";

$xlPhone: "(max-width: 639px)";
$lPhone: "(max-width: 479px)";
$mPhone: "(max-width: 359px)";
$sPhone: "(max-width: 319px)";