.content {
	position: relative;
}
// TOLTIP
.btn .tooltiptext,
.action .tooltiptext {
	visibility:       hidden;
	background-color: black;
	color:            #ffffff;
	text-align:       center;
	border-radius:    6px;
	padding:          5px;
	margin-left:      -100%;
	margin-top:       -150%;
	/* Position the tooltip */
	position:         absolute;
	z-index:          1;
}

.btn:hover .tooltiptext,
.action:hover .tooltiptext {
	visibility: visible;
}

/* ===== CONTENT PANEL ===== */
.content-panel {
	width:         100%;
	padding:       10px 20px;
	position:      relative;
	background:    #ffffff;
	box-shadow:    $box-boxshadow;
	border-bottom: 1px solid $box-border-color;
	display:       block;
	@include border-radius($box-border-radius, $box-border-radius, $box-border-radius, $box-border-radius);
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin:             0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

/* ===== FORM PAGES ===== */
.form-wrapper {
	width:  700px;
	margin: 20px auto;

	&.box {
		.box-header {
			padding: 17px 40px;
		}

		.box-body {
			padding: 20px 40px;
		}

		.box-footer {
			padding:    17px 40px;
			border-top: 1px solid #cdd2db;
			text-align: center;

			button {
				width:   50%;
				display: inline-block;
			}
		}
	}

	&.form-login {
		width: 360px;

		.logo {
			width:          50px;
			display:        inline-block;
			vertical-align: middle;
		}

		h2 {
			display:        inline-block;
			vertical-align: middle;
		}

		.form {
			.form-group {
				padding: 5px 0;
			}
		}

		.box-body {
			position: relative;
		}

		@media only screen and #{$minTabletPort} {
			margin:   0;
			left:     50%;
			top:      50%;
			position: absolute;
			@include transform(translate(-50%, -50%));
		}

		button {
			width:   100%;
			display: block;
		}
	}
}

/* ===== STATUS ===== */
.status-block {
	.status {
		// height: 18px;
		border:         1px solid transparent;
		text-align:     center;
		color:          #ffffff;
		text-transform: uppercase;
		font-size:      10px;
		line-height:    17px;
		padding:        0 10px 0 17px;
		position:       relative;
		@include border-radius(2px, 2px, 2px, 2px);
		@include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.06));

		&:before {
			width:      7px;
			height:     7px;
			background: rgba(255, 255, 255, .35);
			position:   absolute;
			left:       5px;
			top:        5px;
			content:    "";
			@include border-radius(50%, 50%, 50%, 50%);
		}

		&.status-orange {
			background:   #f2784b;
			border-color: #f2784b;
		}

		&.status-red {
			background:   #e05c5c;
			border-color: #e05c5c;
		}

		&.status-green {
			background:   #1bbc9b;
			border-color: #1bbc9b;
		}

		&.status-blue {
			background:   #4893dc;
			border-color: #4893dc;
		}

		&.status-grey {
			background:   #99b0c8;
			border-color: #99b0c8;
		}

		&.status-dark {
			background:   #34495e;
			border-color: #34495e;
		}

		&.status-light-blue {
			background:   #83bbf1;
			border-color: #83bbf1;
		}

		&.status-white {
			background: #ffffff;
			border:     1px solid #e4e4e4;
			color:      #787b8b;
		}
	}

	.status-lateral {
		font-size: 12px;
		padding:   3px 0;
	}

}

/* ===== MEMBERS ===== */
.members {

	.member {
		margin: 0 0 10px 0;

		.box-body {
			padding: 15px;
		}

		.member-logo {
			div {
				border: 3px solid #f2f2f3;
			}
		}

		.member-info {
			h2 {
				padding:   0 0 5px 0;
				font-size: 15px;
			}
			p {
				color: #484a4e;
			}
		}

		.status-block {
			text-align: right;
			.status {
				display: inline-block;
			}

			.status-lateral {
				color: #484a4e;

				i {
					color: #ed5c5c;
				}
			}
		}
	}

}

.member-info-box {
	margin: 0 0 16px 0;

	ul {
		display: block;
		margin:  0;
		padding: 0;

		li {
			display:       block;
			border-bottom: 1px solid #e4e6f0;
			padding:       13px 0;
			&:first-child {
				padding: 0 0 13px 0;
			}
			&:last-child {
				padding:       13px 0 0 0;
				border-bottom: none;
			}

			> div {
				display:        inline-block;
				vertical-align: top;

				&:first-child {
					width:       40%;
					font-family: "Roboto", sans-serif;
					line-height: 20px;
				}
				&:last-child {
					width:  60%;
					margin: 0 0 0 -4px;
				}
			}
		}
	}
}

.user-panel {
	.status-block {
		padding: 10px 0 0 0;
		.status {
			display: inline-block;
		}
	}
}

/* ===== PAGE ACTIONS ===== */
.page-actions {
	float:      right;
	text-align: right;
	position:   relative;
	top:        -7px;

	.btn {
		display:        inline-block;
		vertical-align: top;
	}

	.group-btn {
		display:        inline-block;
		vertical-align: top;
		position:       relative;

		ul.dropdown-menu {
			width:   260px;
			left:    auto;
			right:   0;
			padding: 0;
			margin:  0;
			border:  1px solid #60a8ee;
			@include border-radius(2px, 0, 2px, 2px);
			@include box-shadow(0px 8px 20px 0px rgba(0, 0, 0, 0.16));

			li {
				display:       block;
				text-align:    right;
				border-bottom: 1px solid #d7d8df;
				&:last-child {
					border: none;
				}

				a {
					font-size:   13px;
					white-space: normal;
				}
			}
		}

		&.open {
			> .btn {
				@include border-radius(2px, 2px, 0, 0);
			}
		}
	}

	.form-group {
		display: inline-block;
		padding: 0;

		.checkbox {
			display: inline-block;
			margin:  10px 15px;
		}
	}

	.dropdown-toggle {
		span {
			width:         0;
			height:        0;
			border-left:   3px solid transparent;
			border-right:  3px solid transparent;
			border-bottom: 3px solid #ffffff;
			position:      relative;
			display:       inline-block;
			top:           -4px;
			margin:        0 0 0 4px;
			&:after {
				width:        0;
				height:       0;
				border-left:  3px solid transparent;
				border-right: 3px solid transparent;
				border-top:   3px solid #ffffff;
				position:     absolute;
				left:         -3px;
				bottom:       -7px;
				content:      "";
			}
		}
	}
}

/* ===== COMPANY PREFIX ===== */
.company-prefix {
	width:    100%;
	position: relative;
	display:  block;
	margin:   0 0 10px 0;

	.box-body {
		padding: 20px 40px;

		p {
			text-transform: uppercase;
			font-size:      12px;
			padding:        0 0 2px 0;
			color:          #595f6d;
		}

		h2 {
			font-size: 16px;
		}
	}

	.status-block {
		text-align: right;

		.status {
			display: inline-block;
		}

	}

	.prefix-progress {
		width:      100%;
		height:     5px;
		background: #f3f1f1;
		position:   absolute;
		bottom:     -1px;
		left:       0;

		> div {
			height:     5px;
			background: #468bd9;
		}

		span {
			right:      38px;
			top:        -20px;
			text-align: right;
			position:   absolute;
			color:      #acaeb8;
			font-size:  12px;
		}
	}

	.prefix-master {
		width:          70px;
		height:         50px;
		background:     #ff9933;
		color:          #ffffff;
		text-transform: uppercase;
		font-size:      9px;
		text-align:     center;
		position:       absolute;
		left:           -31px;
		top:            -21px;
		padding:        35px 0 0 0;
		@include transform(rotate(-45deg));

	}
}

.company-prefix-alt-list {
	.company-prefix {
		position: relative;
		margin:   0;

		label {
			width:       100%;
			position:    relative;
			background:  #f6f7f8;
			cursor:      pointer;
			padding:     15px 20px;
			border-left: 2px solid #cccccc;
			margin:      0;
			&:after {
				width:       100%;
				height:      100%;
				position:    absolute;
				left:        0;
				right:       0;
				top:         0;
				bottom:      0;
				background:  #8fc1dc;
				border-left: 2px solid #4081a4;
				content:     "";
				opacity:     0;
				z-index:     1;
			}

			.row {
				position: relative;
				z-index:  2;
			}
		}

		input[type=checkbox] {
			visibility: hidden;
			display:    none;
			&:checked + label:after {
				opacity: 1;
			}
			&:checked + label {
				p, h2 {
					color: #ffffff;
				}
			}
		}

	}
}

/* ===== TABLE ===== */
.table {
	/*border-bottom: 1px solid #e2e2e8;*/
	thead {
		tr {
			th {
				font-size:      12px;
				line-height:    15px;
				background:     #d8dce3;
				color:          #3e4657;
				text-transform: uppercase;
				font-weight:    normal;
				padding:        15px 8px;
				border-bottom:  1px solid #ffffff;
				&:first-child {
					@include border-radius(3px, 0, 0, 0);
				}
				&:last-child {
					@include border-radius(0, 3px, 0, 0);
				}
			}
		}
	}
	tbody {
		tr {
			td {
				border-right:   1px solid #ffffff;
				font-size:      13px;
				color:          #6a7286;
				vertical-align: middle;
				background:     #fcfcfd;
				@include transition(all .2s ease-out);
				&:last-child {
					border-right: none;
				}
			}
			&:nth-child(2n+1) {
				td {
					background: #f0f1f4;
				}
			}
			&:hover {
				td {
					background: #e1e4e9;
				}
			}
		}
	}

	&.table-loading {
		tbody {
			position: relative;
		}
	}

	.status {
		display: inline-block;
	}
}

.table-responsive {
	position: relative;

	.table-loading {
		tbody {
			position: relative;
		}

		.loader {
			&.overplay-loader {
				.loading-content {
					.loading-animation {
						font-size:      3px;
						border-top:     5px solid #d4e2f0;
						border-right:   5px solid #d4e2f0;
						border-bottom:  5px solid #d4e2f0;
						border-left:    5px solid #2586e5;
						display:        inline-block;
						vertical-align: middle;
					}
					.loading-text {
						display:        inline-block;
						vertical-align: middle;
						padding:        0;
					}
				}
			}

		}
	}
}

/* ===== TABS ===== */
.nav-tabs-custom {
	margin: 0;

	ul.nav {
		padding:       0 25px;
		border-bottom: 1px solid #dadce5;

		li {
			border:   none !important;
			margin:   0;
			position: relative;

			a {
				font-size:      14px;
				color:          #a1a3b4;
				font-family:    "Roboto", sans-serif;
				text-transform: uppercase;
				padding:        20px 25px 16px 25px;
				display:        block;
				cursor:         pointer;
				border:         none !important;
				@media only screen and #{$minLaptop} and #{$maxLaptop} {
					padding: 24px 15px 20px 15px;
				}
			}

			&.active {
				&:after {
					width:      100%;
					height:     2px;
					background: #4d93d7;
					position:   absolute;
					content:    "";
					left:       0;
					bottom:     -1px;
				}
			}
		}
	}

	.tab-content {
		width:      75%;
		padding:    20px 25px;
		min-height: 500px;
		@media only screen and #{$minLaptop} and #{$maxLaptop} {
			width: 100%;
		}
	}
}

/* ===== DOCUMENTS ===== */
.document-item {
	background:  #ffffff;
	border:      1px solid #dfe2e6;
	border-left: 3px solid #1bbc9b;
	margin:      0 0 8px 0;
	@include transition(all .2s ease-out);
	@include border-radius(2px, 2px, 2px, 2px);
	@include box-shadow(0px 2px 3px 0px rgba(0, 0, 0, 0.07));

	&:hover {
		position: relative;
		z-index:  9;
		@include box-shadow(0px 11px 17px 0px rgba(0, 0, 0, 0.1));
	}

	&:last-child {
		margin: 0;
	}

	.doc-pane {
		padding:  13px 15px;
		position: relative;

		&:first-child {
			border-right: 1px solid #dfe2e6;
		}

		.doc-icon {
			display:        inline-block;
			vertical-align: middle;
			padding:        0 10px 0 0;

			i {
				font-size: 3.1rem;
				color:     #1bbc9b;
			}
		}

		.doc-title {
			display:        inline-block;
			vertical-align: middle;

			h2 {
				font-size: 14px;
				display:   inline-block;
				a {
					color: #484a5b;
					@include transition(all .2s ease-out);
					&:hover {
						color: #808292;
					}
				}

				.status-block {
					display:        inline-block;
					vertical-align: top;
					.status {
						height:      13px;
						font-size:   9px;
						line-height: 12px;
						padding:     0 5px 0 12px;
						&:before {
							width:  5px;
							height: 5px;
							left:   3px;
							top:    3px;
						}
					}
				}

			}

			p {
				display:   block;
				font-size: 12px;
				color:     #838894;
			}
		}

		.doc-label {
			position: absolute;
			right:    20px;
			top:      50%;
			@include transform(translateY(-50%));

			p {
				color:          #595f6d;
				font-size:      13px;
				display:        inline-block;
				vertical-align: middle;
			}

			span {
				width:          17px;
				height:         17px;
				text-align:     center;
				color:          #ffffff;
				display:        inline-block;
				vertical-align: middle;
				i {
					font-size: 10px;
					position:  relative;
					top:       -2px;
				}
			}

			&.label-grey {
				span {
					background: url("img/star-grey.png") no-repeat center center;
				}
			}
			&.label-green {
				span {
					background: url("img/star-green.png") no-repeat center center;
				}
			}

		}

		&.actions {
			text-align:  right;
			padding-top: 21px;
		}
	}

	&.document-warning {
		border-left: 3px solid #e05c5c;
		.doc-pane {
			.doc-icon {
				i {
					color: #e05c5c;
				}
			}
		}
	}

	&.document-archive {
		border-left: 3px solid #b8bccb;
		.doc-pane {
			.doc-icon {
				i {
					color: #b8bccb;
				}
			}
			.doc-title {
				h2 {
					a {
						color: #96979e;
						&:hover {
							color: #484a5b;
						}
					}
				}
			}
		}
	}
}

/* ===== FILTER ===== */
.filter {
	margin: 0 0 10px 0;

	.box-body {
		padding: 15px;
	}

}

/* ===== ACTIONS ===== */
.actions {
	.action {
		width:      24px;
		height:     24px;
		text-align: center;
		position:   relative;
		color:      #ffffff;
		display:    inline-block;
		padding:    2px 0 0 0;
		@include transition(all .2s ease-out);
		@include border-radius(2px, 2px, 2px, 2px);
		@include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.06));

		i {
			position: absolute;
			left:     50%;
			top:      50%;
			@include transform(translate(-50%, -50%));
		}

		&.action-orange {
			background: #f2784b;
		}

		&.action-green {
			background: #1bbc9b;
		}

		&.action-blue {
			background: #4893dc;
		}

		&.action-grey {
			background: #99b0c8;
		}

		&.action-dark {
			background: #34495e;
		}

		&.action-light-blue {
			background: #83bbf1;
		}

	}
}

/* ===== MODAL ===== */
.modal-dialog {

	.modal-content {
		@include border-radius(2px, 2px, 2px, 2px);

		.modal-header {
			padding:       17px 30px;
			text-align:    left;
			border-bottom: 1px solid #d2d4df;
			@include box-shadow(0px 2px 3px 0px rgba(0, 0, 0, 0.06));

			h2 {
				color:       #484a5b;
				font-size:   18px;
				font-weight: normal;
				padding:     0 3px 0 0;
			}

			i {
				color:       #60a8ee;
				text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
			}

			.close {
				opacity:    1;
				margin-top: 1px;
				.fa {
					color:       #d2d3de;
					font-size:   20px;
					text-shadow: 0 0 0 rgba(0, 0, 0, 0);
				}
			}
		}

		.modal-body {
			padding: 20px 30px;
		}

		.modal-footer {
			border-top: 1px solid #d2d4df;
			text-align: center;

			.btn {
				min-width: 150px;
			}
		}

		.modal-text {
			width:      $modalContentWidth;
			margin:     0 auto;
			padding:    10px 0;
			text-align: center;
		}

		.modal-links {
			ul {
				display: block;
				li {
					display:    block;
					text-align: center;
				}
			}
		}

		.modal-list-alt {
			ul {
				display: block;
				li {
					display:       block;
					border-bottom: 1px solid #e7e7e7;
					padding:       10px 0;
					&:last-child {
						border: none;
					}

					p {
						display: inline-block;

						&:last-child {
							float: right;
						}
					}

				}
			}
		}

		ul {
			margin:  0;
			padding: 0;
		}

	}

	&.hasForm {
		width: 700px;
	}

}

/* ===== FORMS ===== */
.form {

	.row {
		margin-left:  -5px;
		margin-right: -5px;
		> div {
			padding-left:  5px;
			padding-right: 5px;
		}
	}

	.form-group {
		margin-bottom: 0;
		padding:       7px 0;
		position:      relative;

		label {
			margin: 0 0 3px 0;
		}

		.form-control {
			height:     38px;
			border:     1px solid #bdc3d1;
			background: #fcfcfd;
			@include transition(all .2s ease-out);
			@include border-radius(0, 0, 0, 0);
			@include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.06));

			&:hover, &:focus {
				background: #ffffff;
				border:     1px solid #468bd9;
			}

			&.input-upload-image {
				padding: 7px 12px;
				height:  auto;
			}
		}

		.btn-remove {
			display: none;
		}

		.gtin-digits-table,
		.ean8-digits-table {

			.form-control {
				text-align:         center;
				padding:            0;
				border-left-width:  0;
				border-right-width: 0;

				&:disabled {
					background-color: #f0f0f0;
					color:            #bdc3d1;
				}
			}
		}

		.gtin-digits-table tbody tr td:first-child .form-control,
		.ean8-digits-table tbody tr td:first-child .form-control {
			border-left-width: 1px;
		}

		.gtin-digits-table tbody tr td:last-child .form-control,
		.ean8-digits-table tbody tr td:last-child .form-control {
			border-right-width: 1px;
		}

		textarea {
			&.form-control {
				height: auto;
			}
		}

		input:-webkit-autofill {
			-webkit-box-shadow: 0 0 0px 1000px white inset;
		}

		&.required {
			label {
				position: relative;
				&:after {
					position: absolute;
					right:    -8px;
					top:      8px;
					color:    #1f70c0;
					content:  "*";
				}
			}
		}

		.checkbox,
		.radio {
			margin: 0;

			input {
				position: relative;
				top:      1px;
			}

			label {
				padding: 0;
				margin:  0;
			}
		}

	}

	.image-preview {
		width:  45%;
		margin: 10px auto 0 auto;
		border: 5px solid #d8dce3;
	}

	&.form-horizontal {

		.form-group {
			padding:      5px 0;
			margin-left:  -5px;
			margin-right: -5px;
			> div {
				padding-left:  5px;
				padding-right: 5px;
				position:      relative;
			}
		}

	}

	.form-control-feedback {
		color: #99a1b4;
		&.icon-email {
			top: 5px;
			.fa {
				font-size: 14px;
			}
		}
		&.icon-password {
			top: 7px;
			.fa {
				font-size: 18px;
			}
		}
	}

}

.form-block {
	border-top:    1px solid #cdd2db;
	border-bottom: 1px solid #cdd2db;
	padding:       10px 0;
	margin:        10px 0;

	&:last-child {
		border-bottom: none;
		padding:       10px 0 0 0;
		margin:        10px 0 0 0;
	}
}

.form-v-sep {
	margin-bottom: 10px;
	&:last-child {
		margin: 0;
	}
}

.form-group-separator {
	border-bottom: 1px solid #e7e7e7;
	padding:       7px 0;
	margin-bottom: 0 !important;

	&:last-child {
		border:  none;
		padding: 7px 0 0 0;
	}
}

.input-group-add {
	.form-control {
		text-align: center;
	}

	.btn-light {
		padding: 10px 16px;
		@include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.06));
	}
}

.input-group {
	.input-group-addon {
		border-color: #bdc3d1;
		@include border-radius(0, 2px, 0, 2px);
		@include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.06));
	}
}

.login-box {
	.btn {
		width:   100%;
		display: block;
	}
}

.help-block {
	margin:    0;
	padding:   3px 0 0 0;
	font-size: 12px;
}

/* Select */
.select2-dropdown {
	border: 1px solid #bdc3d1;
	@include box-shadow(0px 10px 25px 0px rgba(0, 0, 0, 0.07));
}
.select2-container--default .select2-selection--single,
.select2-selection .select2-selection--single {
	height: 38px;
	border: 1px solid #bdc3d1;
	@include transition(all .2s ease-out);
	@include border-radius(2px, 2px, 2px, 2px);
	@include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.06));
}
.select2-selection__rendered {
	margin: 0 !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 34px;
}
.select2-container--open {
	.select2-selection {
		border: 1px solid $light-blue;
	}
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 26px;
}

/* ===== MISC ===== */
.content-center {
	text-align: center;
}

.empty-content {
	padding:    50px 0;
	text-align: center;
	color:      #81899b;

	p {
		padding:    5px 0 0 0;
		color:      #81899b;
		font-style: italic;
	}

	.fa {
		font-size: 54px;
		color:     #b8bdc9;
	}
}

/* ===== MESSAGES ===== */
.field-error {

	label {
		color: #dd4b39;
	}

	.form-control {
		background: #ffffff !important;
		border:     1px solid #dd4b39 !important;
	}

	.error-message {
		padding:   2px 0 0 0;
		font-size: 12px;
		color:     #dd4b39;
	}

}

.global-message-form {
	padding:    3px 20px;
	font-size:  13px;
	text-align: center;
	color:      #ffffff;
	@include border-radius(2px, 2px, 2px, 2px);

	p {
		color: #ffffff;
	}

	&.success-message {
		background: rgba(50, 186, 107, .75);
		border:     1px solid #20a859;
	}

	&.error-message {
		background: rgba(209, 54, 54, .75);
		border:     1px solid #d13636;
	}
}

.status-message {
	text-align:  center;
	padding:     10px 0 0 0;
	font-weight: bold;

	&.message-success {
		color: #1bbc9b;
	}

	&.error-message {
		color: #dd4b39;
	}

}

.global-message-page-holder {
	position: absolute;
	top:      125px;
	left:     50%;
	@include transform(translateX(-50%));
	z-index:  99;

	.global-message-page {
		position:      relative;
		padding:       10px 40px 10px 20px;
		margin-bottom: 5px;
		color:         #ffffff;
		top:           40px;
		opacity:       0;
		@include transition(all .1s ease-out);
		@include border-radius(2px, 2px, 2px, 2px);
		@include box-shadow(0px 3px 12px 0px rgba(0, 0, 0, 0.2));

		span {
			width:          22px;
			height:         22px;
			display:        inline-block;
			vertical-align: middle;
			text-align:     center;
			position:       relative;
			@include border-radius(50%, 50%, 50%, 50%);
			i {
				position: absolute;
				left:     50%;
				top:      50%;
				@include transform(translate(-50%, -50%));
			}
		}

		p {
			color:          #ffffff;
			padding:        0 0 0 5px;
			display:        inline-block;
			vertical-align: middle;
		}

		a.close {
			position:  absolute;
			font-size: 12px;
			right:     20px;
			top:       50%;
			@include transform(translateY(-50%));
		}

		&.success-message {
			background: rgba(50, 186, 107, .75);
			border:     1px solid #20a859;

			span {
				background: #32ba6b;
			}
		}

		&.error-message {
			background: rgba(209, 54, 54, .75);
			border:     1px solid #d13636;

			span {
				background: #dc4141;
				.fa {
					font-size: 11px;
				}
			}
		}

		&.animation {
			opacity: 1;
			top:     0;
		}

	}
}

.full-page-error {
	.wrapper {
		@include blur(3px);
	}

	.page-error {
		width:      100%;
		height:     100%;
		position:   fixed;
		z-index:    99999;
		background: rgba(17, 20, 29, .85);

		.error-caption {
			width:      400px;
			position:   absolute;
			left:       50%;
			top:        50%;
			text-align: center;
			@include transform(translate(-50%, -50%));

			div {
				width:   130px;
				display: inline-block;
			}

			h2 {
				color:          #ffffff;
				font-size:      25px;
				text-transform: uppercase;
				padding:        10px 0 0 0;
			}

			p {
				color:     #ffffff;
				font-size: 16px;
				padding:   5px 0 10px 0;
			}
		}
	}
}

/* ===== LOADERS ===== */
.loader {
	&.overplay-loader {
		width:      100%;
		height:     100%;
		position:   absolute;
		left:       0;
		top:        0;
		bottom:     0;
		right:      0;
		z-index:    99;
		background: rgba(255, 255, 255, .8);

		.loading-content {
			position: absolute;
			left:     50%;
			top:      50%;
			@include transform(translate(-50%, -50%));

			.loading-text {
				padding:    10px 0 0 0;
				text-align: center;
			}
			.loading-animation {
				border-radius:     50%;
				width:             10em;
				height:            10em;
				margin:            0 auto;
				font-size:         7px;
				position:          relative;
				text-indent:       -9999em;
				border-top:        10px solid #d4e2f0;
				border-right:      10px solid #d4e2f0;
				border-bottom:     10px solid #d4e2f0;
				border-left:       10px solid #2586e5;
				-webkit-transform: translateZ(0);
				-ms-transform:     translateZ(0);
				transform:         translateZ(0);
				-webkit-animation: load8 1.1s infinite linear;
				animation:         load8 1.1s infinite linear;
				&:after {
					border-radius: 50%;
					width:         10em;
					height:        10em;
				}
			}
			@-webkit-keyframes load8 {
				0% {
					-webkit-transform: rotate(0deg);
					transform:         rotate(0deg);
				}
				100% {
					-webkit-transform: rotate(360deg);
					transform:         rotate(360deg);
				}
			}
			@keyframes load8 {
				0% {
					-webkit-transform: rotate(0deg);
					transform:         rotate(0deg);
				}
				100% {
					-webkit-transform: rotate(360deg);
					transform:         rotate(360deg);
				}
			}
		}

	}
}

/* ===== HELP ===== */
.help {
	position: absolute;
	right:    0;

	.question-mark {
		color:  #c9c9d0;
		cursor: pointer;
		@include transition(all .2s ease-out);
		&:hover {
			color: #9295b2;
		}
	}

	.help-content {
		width:      400px;
		right:      0;
		position:   absolute;
		z-index:    99;
		background: #ffffff;
		border:     1px solid #dcdce0;
		@include border-radius(2px, 2px, 2px, 2px);
		@include box-shadow(0px 3px 12px 0px rgba(0, 0, 0, 0.2));

		.help-heading {
			padding:       10px 20px;
			border-bottom: 1px solid #dcdce0;
			@include box-shadow(0px 2px 3px 0px rgba(0, 0, 0, 0.06));

			h3 {
				text-transform: uppercase;
				font-size:      13px;
				display:        inline-block;
				color:          #60a8ee;
			}

			a {
				float: right;
				color: #d2d3de;
				&:hover {
					color: #9295b2;
				}
			}
		}

		.help-body {
			max-height:  250px;
			overflow:    auto;
			padding:     20px;
			line-height: 20px;

			p {
				font-size:   13px;
				line-height: 20px;
				padding:     0 0 10px 0;
				color:       #6a7286;
			}

			a {
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}

			b, strong {
				color: #595f6d;
			}

			h2 {
				font-size: 14px;
				padding:   0 0 7px 0;
			}

			ul {
				margin: 0 0 10px 12px;

				li {
					color:       #6a7286;
					font-size:   13px;
					line-height: 20px;
				}
			}

			img {
				border: 5px solid #ece9e9;
				margin: 0 0 10px 0;
			}
		}
	}

}

.form-horizontal {
	.help {
		right: -13px;
		top:   12px;
	}
}
