/*
 * Component: Button
 * -----------------
 */

.btn {
  @include border-radius($btn-border-radius,$btn-border-radius,$btn-border-radius,$btn-border-radius);
	@include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.2));
  border: none;
	color: #fff;
	padding: 9px 20px;
	@include transition(all .2s ease-out);
	outline: none!important;
	&:hover {
		color: #fff;
	}

	.fa {
		font-size: 12px;
		padding: 0 2px 0 0;
	}

  &.uppercase {
    text-transform: uppercase
  }

  // Flat buttons
  &.btn-flat {
    @include border-radius(0,0,0,0);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-width: 1px;
  }

  // Active state
  &:active {
	  color: #fff;
	  text-decoration: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }

  &:focus {
    outline: none;
	  color: #fff;
	  text-decoration: none;
  }

  // input file btn
  &.btn-file {
    position: relative;
    overflow: hidden;
    > input[type='file'] {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      font-size: 100px;
      text-align: right;
      @include opacity(0);
      outline: none;
      background: white;
      cursor: inherit;
      display: block;
    }
  }
}

//Button color variations
.btn-default {
  background-color: #f4f4f4;
  border-color: #3274c0;
  &:hover,
  &:active,
  &.hover {
    background-color: darken(#f4f4f4, 5%);
  }
}

.btn-primary {
	background-color: #60a8ee;
	&:hover {
		background-color: #488acb;
	}
}

.btn-secondary {
	background-color: #97afc8;
	&:hover {
		background-color: #7d91a5;
	}
}

.btn-third {
	background-color: #1bbc9b;
	&:hover {
		background-color: #16a688;
	}
}

.btn-alt {
	color: #fff;
	background-color: #8b8da7;
	border-color: #5f617c;
	@include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.2));
	&:hover, &:active, &.hover {
		background-color: #7d7f98;
		color: #fff;
	}
}

.btn-light {
	color: #7f8191;
	background-color: #e5e7ec;
	border-color: #bdc3d1;
	@include box-shadow(0px 2px 4px 0px rgba(0, 0, 0, 0.2));
	&:hover, &:active, &.hover {
		background-color: #d8dce4;
		color: #7f8191;
	}
}

.btn-success {
  background-color: $green;
  border-color: darken($green, 5%);
  &:hover, &:active, &.hover {
    background-color: darken($green, 5%);
  }
}

.btn-info {
  background-color: $aqua;
  border-color: darken($aqua, 5%);
  &:hover, &:active, &.hover {
    background-color: darken($aqua, 5%);
  }
}

.btn-danger {
  background-color: $red;
  border-color: darken($red, 5%);
  &:hover, &:active, &.hover {
    background-color: darken($red, 5%);
  }
}

.btn-warning {
  background-color: $yellow;
  border-color: darken($yellow, 5%);
  &:hover, &:active, &.hover {
    background-color: darken($yellow, 5%);
  }
}

.btn-outline {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  &:hover,
  &:focus,
  &:active {
    color: rgba(255, 255, 255, .7);
    border-color: rgba(255, 255, 255, .7);
  }
}

.btn-link {
  @include box-shadow(none);
}

//General .btn with bg class
.btn[class*='bg-']:hover {
  @include box-shadow(inset 0 0 100px rgba(0, 0, 0, 0.2));
}

// Application buttons
.btn-app {
  @include border-radius(3px,3px,3px,3px);
  position: relative;
  padding: 15px 5px;
  margin: 0 0 10px 10px;
  min-width: 80px;
  height: 60px;
  text-align: center;
  color: #666;
  border: 1px solid #ddd;
  background-color: #f4f4f4;
  font-size: 12px;
  //Icons within the btn
  > .fa, > .glyphicon, > .ion {
    font-size: 20px;
    display: block;
  }

  &:hover {
    background: #f4f4f4;
    color: #444;
    border-color: #aaa;
  }

  &:active, &:focus {
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }

  //The badge
  > .badge {
    position: absolute;
    top: -3px;
    right: -10px;
    font-size: 10px;
    font-weight: 400;
  }
}
