/*
 * Page: Profile
 * -------------
 */

.profile-user-img {
  margin: 0 auto;
  width: 100px;
  padding: 3px;
  border: 3px solid $gray;
}

.profile-username {
  font-size: 21px;
  margin-top: 5px;
}

.post {
  border-bottom: 1px solid $gray;
  margin-bottom: 15px;
  padding-bottom: 15px;
  color: #666;
  &:last-of-type {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .user-block {
    margin-bottom: 15px;
  }
}
