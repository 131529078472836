/*
 * Component: Label
 * ----------------
 */
.label-default {
  background-color: $gray;
  color: #444;
}

.label-danger {
  @extend .bg-red;
}

.label-info {
  @extend .bg-aqua;
}

.label-warning {
  @extend .bg-yellow;
}

.label-primary {
  @extend .bg-light-blue;
}

.label-success {
  @extend .bg-green;
}
